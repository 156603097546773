// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";
import "select2/dist/js/select2.min.js";
// custom

function slickCarousel() {
    jQuery(".book_slider").slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        variableWidth: false,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 8000,
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });
}

function slickCarouseltop() {
    jQuery(".home_top_slider").slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 8000,
        responsive: [
            {
                breakpoint: 1370,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
}

function slickCarouselkid() {
    jQuery(".kid_sub_slide").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 8000,
    });
}

function category_more_posts(html, get) {
    let str = "action=get_category_more_posts";
    str += `&type=${html}`;
    const category_term = get;
    if (category_term != "") {
        str += `&category_term=${category_term}`;
    }
    jQuery.ajax({
        url: l10n.post_list_admin_URL_NAME.admin_URL,
        type: "POST",
        dataType: "JSON",
        data: str,
        beforeSend: () => {
            if (html == "html") {
                jQuery("#appenddata").hide();
                jQuery("#html_loader").addClass("html_loader");
            }
        },
        success: (data) => {
            jQuery("#html_loader").removeClass("html_loader");
            jQuery("#appenddata").show();
            const responseData = JSON.parse(JSON.stringify(data));
            if (responseData.Nodata == "") {
                jQuery("#appenddata").html("<h4 class='not-found-title'> No Books Available</h4>");
            } else {
                if (html == "html") {
                    if (responseData.result) {
                        jQuery("#appenddata").html(responseData.result);
                        slickCarousel();
                    } else {
                        jQuery("#appenddata").html("No Events found");
                    }
                } else {
                    jQuery("#appenddata").append(responseData.result);
                }
            }
        }, error: () => {
            console.log("carousel items failed to load");
        }
    });
}

/* load category post */

jQuery(".category_term").on("click",function () {
    const html = "html";
    jQuery(".category_term").removeClass("active");
    jQuery(this).addClass("active");
    event.preventDefault();
    const get = jQuery(this).attr("data-term_id");
    category_more_posts(html, get);
});

/* SEARCH TOGGLE JS */
jQuery("button#find-it-btn").on("click", function () {
    const search_type = jQuery("#search_type").val();
    const search = encodeURIComponent(jQuery("#search").val());
    if (search_type != "" && search != "") {
        if (search_type == "catalog") {
            const url = `https://willimantic.biblio.org/eg/opac/results?qtype=keyword&locg=123&query=${search}&submit=GO`;
            window.open(url, "_blank");
        } else {
            return true;
        }
    }
    return false;
});

jQuery("button#find-it-btn-mobile").on( "click", function () {
    const search_link = jQuery("#search_link_mobile").attr("data-link");
    const search_key = jQuery("#search_key_mobile").attr("data-key");
    const search_type = jQuery("#search_type_mobile").val();
    const search = jQuery("#search_mobile").val();
    if (search_type != "" && search != "") {
        if (search_type == "catalog") {
            const url = `${search_link}?${search_key}=${search}`;
            window.open(url, "_blank");
        } else {
            return true;
        }
    }
    return false;
});

jQuery("#find-it-btn-mobile_sticy").on("click", function () {
    const search_link = jQuery("#search_link_sticky").attr("data-link");
    const search_key = jQuery("#search_key_sticky").attr("data-key");
    const search_type = jQuery("#search_type_sticky").val();
    const search = jQuery("#search_sticky").val();
    if (search_type != "" && search != "") {
        if (search_type == "catalog") {
            const url = `${search_link}?${search_key}=${search}`;
            window.open(url, "_blank");
        } else {
            return true;
        }
    }
    return false;
});

function setTranslateText() {
    const translateTextDesktop = jQuery(".desk_header .gt_selector option:selected").text();
    const translateTextMobile = jQuery(".mobile_header .gt_selector option:selected").text();
    console.log(`Desktop: ${translateTextDesktop}`);
    console.log(`Mobile: ${translateTextMobile}`);
    jQuery(".desk_header .glink").text(`Translate: ${translateTextDesktop}`);
    jQuery(".mobile_header .glink").text(`Translate: ${translateTextMobile}`);
}

jQuery(document).ready(function () {
    setTranslateText();
    const gtSelectors = document.querySelectorAll(".gt_selector");
    gtSelectors.forEach((single) => {
        single.addEventListener("change", () => {
            setTranslateText();
        });
    });

    setTimeout(function () {
        jQuery(".goog-te-combo").change(function () {
            if (jQuery(".goog-te-combo").val()) {
                const phone = jQuery(".phone-no-cls-dis").attr("href");
                jQuery(".phone-cls-header").text(phone);
                jQuery(".phone-cls-footer").text(phone);
            }
        });

    }, 3000);


    slickCarousel();
    slickCarouseltop();
    slickCarouselkid();

    jQuery("#search_type").select2();
});

jQuery(".main_fix_header .search_btn").on("click", function () {
    document.querySelector(".main_fix_header #search_fix").classList.toggle("open");
    console.log("clicked");
});
